import { db } from "../../firebase/firebase-config";

export const registerRequest = async (data, currentType) => {
  try {
    // 1) Possibly do your external fetch...
    const res = await fetch(
      "https://us-central1-revo-47fdb.cloudfunctions.net/mailer/requestReceived",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          name: data.name,
        }),
      }
    );
    console.log(res);

    if (res.ok) {
      const requestRef = db.collection("dbRefs").doc("requests");
      const docSnap = await requestRef.get();
      if (!docSnap.exists) {
        throw new Error("Document not found");
      }
      const currentCount = docSnap.data()[currentType] || 0;
      const newCount = currentCount + 1;
      await requestRef.update({ [currentType]: newCount });

      const updatedData = {
        ...data,
        requestEmailSent: true,
      };
      const docRef = await db
        .collection("contacts")
        .doc("register")
        .collection("requests")
        .add(updatedData);

      return docRef.id;
    } else {
      throw new Error("Mailer request failed");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkIfUserExists = async (brokerId) => {
  const querySnapshot = await db
    .collection("users")
    .where("brokerId", "==", brokerId)
    .get();
  return !querySnapshot.empty;
};
export const deleteAccount = async (data) => {
  try {
    // Assuming 'bcollection' is a typo and you meant 'firestore'
    await db
      .collection("contacts")
      .doc("deleteRequests")
      .collection("requests")
      .add(data);
  } catch (error) {
    console.error(error);
    // Handle the error here or rethrow it if needed
    throw error;
  }
};
