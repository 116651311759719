import * as yup from "yup";

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { blue, yellow } from "../../styles/styles";
import { checkIfUserExists, registerRequest } from "../../utils/firebaseUtils";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { RevoButton } from "../../components/general/RevoButton";
import Swal from "sweetalert2";
import { db } from "../../../firebase/firebase-config";
import { registerForm } from "../../utils/formData";
import { uploadFileToStorage } from "../../components/utils/FileUploadHelpers";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const Register = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [depositProofFile, setDepositProofFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  // ──────────────────────────────────────────────────────────
  // Validation Schema
  // ──────────────────────────────────────────────────────────
  const validationSchema = yup.object().shape({
    name: yup.string().required("Campo obligatorio"),
    lastname: yup.string().required("Campo obligatorio"),
    email: yup
      .string()
      .email("Introduce una dirección de correo electrónico válida")
      .required("Campo obligatorio"),
    confirmEmail: yup
      .string()
      .oneOf(
        [yup.ref("email"), null],
        "Los correos electrónicos deben coincidir"
      )
      .required("Campo obligatorio"),
    selectedAnswer: yup.number().required("Campo obligatorio"),
    agreeToTerms: yup
      .boolean()
      .oneOf([true], "Debes aceptar los términos y condiciones"),
    discordId: yup.string().required("Campo obligatorio"),
    brokerId: yup
      .string()
      .required("Campo obligatorio")
      .test(
        "startsWith18or19",
        "El ID de broker es inválido",
        (value) => value && (value.startsWith("18") || value.startsWith("19"))
      ),
    phone: yup.string().required("Campo obligatorio"),
  });

  // ──────────────────────────────────────────────────────────
  // Formik
  // ──────────────────────────────────────────────────────────
  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      agreeToTerms: false,
      selectedAnswer: 0,
      discordId: "",
      brokerId: "",
      phone: "",
      email: "",
      confirmEmail: "",
      referrer: "",
    },
    enableReinitialize: false,
    validateOnMount: false,
    validationSchema,
    onSubmit: async (values) => {
      // Confirm submission
      const confirmResult = await Swal.fire({
        title: "¿Deseas enviar tu solicitud?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, enviar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });
      if (!confirmResult.isConfirmed) return;

      // Show loading
      const loadingSwal = Swal.fire({
        title: "Enviando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const dataToSend = {
          ...values,
          cdate: new Date(),
          status: "pending",
          reason:
            values.selectedAnswer === 1
              ? "Es mi primer ingreso a Revolution"
              : "Ya tengo acceso a la app, solo quiero acceso al curso avanzado",
        };

        let currentType = "pendingFirst";
        if (values.selectedAnswer !== 1) {
          currentType = "pendingUpgrade";
        }

        // 1) Create doc in Firestore => newRequestId
        const newRequestId = await registerRequest(dataToSend, currentType);

        // 2) Upload file if present
        let depositProofURL = null;
        if (depositProofFile) {
          const fileName = depositProofFile.name;
          const filePath = `images/register/requests/${newRequestId}/${fileName}`;

          if (!depositProofFile.type.startsWith("image/")) {
            throw new Error("Solo se permiten archivos de tipo imagen.");
          }

          depositProofURL = await uploadFileToStorage(
            depositProofFile,
            filePath
          );
        }

        // 3) If there's a URL, update doc with depositProofURL
        if (depositProofURL) {
          await db
            .collection("contacts")
            .doc("register")
            .collection("requests")
            .doc(newRequestId)
            .update({ depositProofURL });
        }

        // 4) Reset form & close loading
        formik.resetForm();
        setDepositProofFile(null);
        loadingSwal.close();

        // 5) Success alert
        MySwal.fire({
          title: `Solicitud enviada`,
          icon: "success",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancelar",
        });
      } catch (error) {
        loadingSwal.close();
        MySwal.fire("Error", "Hubo un error al crear el usuario", "error");
        console.error(error);
      }
    },
  });

  // Check if brokerId starts with "19" => deposit proof is enabled
  const isBroker19 = formik.values.brokerId?.startsWith("19");

  // ──────────────────────────────────────────────────────────
  // Drag & Drop Logic (disabled if broker starts with "18")
  // ──────────────────────────────────────────────────────────
  const handleDrop = (e) => {
    if (!isBroker19) return; // skip if disabled
    e.preventDefault();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith("image/")) {
        setDepositProofFile(file);
      } else {
        Swal.fire(
          "Error",
          "Solo se permiten archivos de tipo imagen.",
          "error"
        );
      }
      e.dataTransfer.clearData();
    }
  };

  const handleDragOver = (e) => {
    if (!isBroker19) return;
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    if (!isBroker19) return;
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    if (!isBroker19) return;
    e.preventDefault();
    setIsDragging(false);
  };

  const preventRightClick = (event) => {
    event.preventDefault();
  };
  const handleCopy = (e) => {
    e.preventDefault();
  };

  return (
    <Container maxWidth="lg">
      <Grid container sx={{ minHeight: "75vh" }} pt={22} pb={10}>
        <Grid container item justifyContent="center" alignItems="center" mb={8}>
          <Grid item>
            <Typography variant="h5" sx={{ fontSize: 18, color: "white" }}>
              FORMULARIO DE INGRESO A REVOLUTION
            </Typography>
          </Grid>
        </Grid>

        <Box
          component="form"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* ──────────────────────────────────────────────────────────
              Question #1: Reason for joining
          ────────────────────────────────────────────────────────── */}
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            mb={4}
          >
            <Grid item md={10} mb={1} xs={11} sm={11}>
              <FormLabel>
                <Typography variant="h7" sx={{ fontSize: 18, color: "white" }}>
                  {registerForm[0].question}
                </Typography>
              </FormLabel>
              <Grid item md={12} mb={1}>
                <Box mt={2}>
                  <Typography
                    sx={{
                      fontWeight: "400 !important",
                      fontSize: "12px !important",
                      textTransform: "capitalize",
                      fontStyle: "italic",
                      pl: 2,
                    }}
                  >
                    RESPONDE TILDANDO SOLO LA OPCION QUE CORRESPONDE:
                  </Typography>
                </Box>
              </Grid>
              <FormControl component="fieldset" sx={{ mt: 2, width: "100%" }}>
                <RadioGroup
                  name="selectedAnswer"
                  value={formik.values.selectedAnswer.toString()}
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue("selectedAnswer", +e.target.value)
                  }
                >
                  {registerForm[0].answers.map((answer) => (
                    <FormControlLabel
                      key={answer.id}
                      value={answer.id.toString()}
                      control={<Radio />}
                      label={answer.answer}
                      sx={{
                        borderColor:
                          answer.id.toString() === formik.values.selectedAnswer
                            ? blue
                            : "#A1A1A1",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderRadius: "18px",
                        padding: 0.5,
                        margin: 1,
                        marginLeft: 1,
                        "& .MuiRadio-colorPrimary.Mui-checked": {
                          color: yellow,
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
                {formik.touched.selectedAnswer &&
                  formik.errors.selectedAnswer && (
                    <FormHelperText error>
                      {formik.errors.selectedAnswer}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
          </Grid>

          {/* ──────────────────────────────────────────────────────────
              NOMBRE / APELLIDO
          ────────────────────────────────────────────────────────── */}
          <Grid
            container
            item
            justifyContent={{ md: "space-between", xs: "center", sm: "center" }}
            alignItems="center"
            md={10}
            xs={12}
            sm={12}
            columnGap={1}
          >
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box>
                <Typography>
                  <span style={{ color: theme.palette.primary.secondary }}>
                    1.
                  </span>{" "}
                  NOMBRE Y APELLIDO
                </Typography>
              </Box>
            </Grid>
            <Grid item md={5.6} xs={11} sm={11}>
              <Box style={{ height: "80px" }}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  id="name"
                  name="name"
                  label="Nombre"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Box>
            </Grid>
            <Grid item md={5.6} xs={11} sm={11}>
              <Box style={{ height: "80px" }}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  id="lastname"
                  name="lastname"
                  label="Apellido"
                  value={formik.values.lastname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastname && Boolean(formik.errors.lastname)
                  }
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
              </Box>
            </Grid>
          </Grid>

          {/* ──────────────────────────────────────────────────────────
              ID DE BROKER
          ────────────────────────────────────────────────────────── */}
          <Grid
            container
            item
            justifyContent={{ md: "space-between", xs: "center", sm: "center" }}
            alignItems="center"
            md={10}
            my={2}
          >
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box mb={2}>
                <Typography>
                  <span style={{ color: theme.palette.primary.secondary }}>
                    2.
                  </span>{" "}
                  ID de tu cuenta real del broker
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "400 !important",
                    fontSize: "12px !important",
                    pl: 2,
                    pr: 2,
                  }}
                >
                  ingresa el ID de la cuenta real del broker que estas
                  utilizando con nosotros
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box style={{ height: "80px" }}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  id="brokerId"
                  name="brokerId"
                  label="ID de tu cuenta real del broker"
                  value={formik.values.brokerId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.brokerId && Boolean(formik.errors.brokerId)
                  }
                  helperText={
                    formik.touched.brokerId ? formik.errors.brokerId : ""
                  }
                />
              </Box>
            </Grid>
          </Grid>

          {/* ──────────────────────────────────────────────────────────
              USUARIO DE DISCORD
          ────────────────────────────────────────────────────────── */}
          <Grid
            container
            item
            justifyContent={{ md: "space-between", xs: "center", sm: "center" }}
            alignItems="center"
            md={10}
            my={2}
          >
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box mb={2}>
                <Typography>
                  <span style={{ color: theme.palette.primary.secondary }}>
                    3.
                  </span>{" "}
                  TU USUARIO DE DISCORD
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "400 !important",
                    fontSize: "12px !important",
                    pl: 2,
                    pr: 2,
                  }}
                >
                  ingresa tu usuario de Discord.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box style={{ height: "80px" }}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  id="discordId"
                  name="discordId"
                  label="TU USUARIO DE DISCORD"
                  value={formik.values.discordId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.discordId && Boolean(formik.errors.discordId)
                  }
                  helperText={
                    formik.touched.discordId && formik.errors.discordId
                  }
                />
              </Box>
            </Grid>
          </Grid>

          {/* ──────────────────────────────────────────────────────────
              CORREO ELECTRONICO
          ────────────────────────────────────────────────────────── */}
          <Grid
            container
            item
            justifyContent={{ md: "space-between", xs: "center", sm: "center" }}
            alignItems="center"
            md={10}
            my={2}
          >
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box mb={2}>
                <Typography>
                  <span style={{ color: theme.palette.primary.secondary }}>
                    4.
                  </span>{" "}
                  CORREO ELECTRÓNICO
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "400 !important",
                    fontSize: "12px !important",
                    pl: 2,
                    pr: 2,
                  }}
                >
                  Escribí el mail que utilizés frecuentemente, allí recibirás el
                  usuario y clave para entrar a la app, corroborá el mail, los
                  puntos y el @.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box style={{ height: "80px" }}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  id="email"
                  name="email"
                  label="CORREO ELECTRÓNICO"
                  value={formik.values.email}
                  onCut={handleCopy}
                  onCopy={preventRightClick}
                  onContextMenu={preventRightClick}
                  onPaste={handleCopy}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Box>
            </Grid>
          </Grid>

          {/* ──────────────────────────────────────────────────────────
              CONFIRMAR CORREO ELECTRONICO
          ────────────────────────────────────────────────────────── */}
          <Grid
            container
            item
            justifyContent={{ md: "space-between", xs: "center", sm: "center" }}
            alignItems="center"
            md={10}
            my={2}
          >
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box mb={2}>
                <Typography>
                  <span style={{ color: theme.palette.primary.secondary }}>
                    5.
                  </span>{" "}
                  CONFIRMAR CORREO ELECTRÓNICO
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box style={{ height: "80px" }}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  id="confirmEmail"
                  name="confirmEmail"
                  label="Confirmar correo electrónico"
                  value={formik.values.confirmEmail}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmEmail &&
                    Boolean(formik.errors.confirmEmail)
                  }
                  helperText={
                    formik.touched.confirmEmail && formik.errors.confirmEmail
                  }
                />
              </Box>
            </Grid>
          </Grid>

          {/* ──────────────────────────────────────────────────────────
              NUMERO DE TELEFONO
          ────────────────────────────────────────────────────────── */}
          <Grid
            container
            item
            justifyContent={{ md: "space-between", xs: "center", sm: "center" }}
            alignItems="center"
            md={10}
            my={2}
          >
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box mb={2}>
                <Typography>
                  <span style={{ color: theme.palette.primary.secondary }}>
                    6.
                  </span>{" "}
                  NUMERO DE TELÉFONO
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box style={{ height: "80px" }}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  id="phone"
                  name="phone"
                  label="NUMERO DE TELÉFONO"
                  value={formik.values.phone}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Box>
            </Grid>
          </Grid>

          {/* ──────────────────────────────────────────────────────────
              PERSONA CON LA QUE INICIASTE
          ────────────────────────────────────────────────────────── */}
          <Grid
            container
            item
            justifyContent={{ md: "space-between", xs: "center", sm: "center" }}
            alignItems="center"
            md={10}
            my={2}
          >
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box mb={2}>
                <Typography>
                  <span style={{ color: theme.palette.primary.secondary }}>
                    7.
                  </span>{" "}
                  PERSONA CON LA QUE INICIASTE
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "400 !important",
                    fontSize: "12px !important",
                    pl: 2,
                    pr: 2,
                  }}
                >
                  Nombre y apellido de la persona con la que comenzaste en
                  Revolution
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} mb={1} xs={11} sm={11}>
              <Box style={{ height: "80px" }}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  id="referrer"
                  name="referrer"
                  label="PERSONA CON LA QUE INICIASTE"
                  value={formik.values.referrer}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.referrer && Boolean(formik.errors.referrer)
                  }
                  helperText={formik.touched.referrer && formik.errors.referrer}
                />
              </Box>
            </Grid>
          </Grid>

          {isBroker19 && (
            <Grid
              container
              item
              justifyContent={{
                md: "space-between",
                xs: "center",
                sm: "center",
              }}
              alignItems="center"
              md={10}
              my={2}
            >
              <Grid item md={12} mb={1} xs={11} sm={11}>
                <Box mb={2}>
                  <Typography>
                    <span style={{ color: theme.palette.primary.secondary }}>
                      8.
                    </span>{" "}
                    FOTO DEL DEPÓSITO EN CUENTA
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontWeight: 400, fontSize: 12, pl: 2, pr: 2 }}
                  >
                    {`Sube la foto que compruebe tu depósito en tu cuenta real del broker. También puedes arrastrar y soltar una imagen aquí.
       `}
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={12} mb={1} xs={11} sm={11}>
                <Box
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  sx={{
                    border: isDragging
                      ? `2px dashed ${yellow}`
                      : "2px dashed #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: formik.values.brokerId?.startsWith("19")
                      ? "pointer"
                      : "not-allowed",
                    mb: 2,
                  }}
                >
                  {depositProofFile ? (
                    <Typography sx={{ fontSize: 14 }}>
                      Archivo seleccionado: {depositProofFile.name}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: 14 }}>
                      {formik.values.brokerId?.startsWith("19")
                        ? "Arrastra tu imagen aquí o haz click en el botón para seleccionar."
                        : "Broker ID inicia con '18': no se requiere imagen."}
                    </Typography>
                  )}
                </Box>

                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="deposit-proof-file"
                  type="file"
                  disabled={!formik.values.brokerId?.startsWith("19")}
                  onChange={(e) => {
                    if (!formik.values.brokerId?.startsWith("19")) return;
                    if (e.currentTarget.files && e.currentTarget.files[0]) {
                      const file = e.currentTarget.files[0];
                      if (!file.type.startsWith("image/")) {
                        Swal.fire(
                          "Error",
                          "Solo se permiten archivos de tipo imagen.",
                          "error"
                        );
                      } else {
                        setDepositProofFile(file);
                      }
                    }
                  }}
                />
                <label htmlFor="deposit-proof-file">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<PhotoCameraIcon />}
                    sx={{ backgroundColor: yellow, color: "black" }}
                    disabled={!formik.values.brokerId?.startsWith("19")}
                  >
                    Seleccionar archivo
                  </Button>
                </label>
              </Grid>
            </Grid>
          )}

          {/* ──────────────────────────────────────────────────────────
              Acepto Términos
          ────────────────────────────────────────────────────────── */}
          <Grid
            container
            item
            justifyContent={{
              md: "space-between",
              xs: "center",
              sm: "center",
              lg: "space-between",
            }}
            alignItems="center"
            md={10}
            my={2}
          >
            <FormControl
              component="fieldset"
              sx={{
                flexDirection: "row",
                marginBottom: "15px",
                mr: 1,
              }}
            >
              <FormControlLabel
                sx={{
                  marginLeft: "0px !important",
                  flexDirection: "row",
                }}
                value="agreeToTerms"
                label={
                  <Typography>
                    Acepto los{" "}
                    <a
                      href="https://revolutionarg.com/terminos"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: yellow }}
                    >
                      Términos y Condiciones
                    </a>
                  </Typography>
                }
                labelPlacement="start"
                control={
                  <Checkbox
                    name="agreeToTerms"
                    checked={formik.values.agreeToTerms}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
              />
              {formik.touched.agreeToTerms && formik.errors.agreeToTerms && (
                <FormHelperText error>
                  {formik.errors.agreeToTerms}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

      
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            my={4}
          >
            <Grid
              container
              item
              md={10}
              justifyContent="center"
              alignItems="center"
            >
              <RevoButton
                buttonText="Enviar"
                backgroundColor={blue}
                color="white"
                disabled={!formik.isValid}
                shadow={blue}
                onClick={formik.handleSubmit}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};
