import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";

import imageCompression from "browser-image-compression";

const storage = getStorage();

export const uploadFileToStorage = async (file, filePath) => {
  // 1) Only allow images
  if (!file.type.startsWith("image/")) {
    throw new Error("Only image files can be uploaded.");
  }

  // 2) Compress to roughly 200 KB
  const compressionOptions = {
    maxSizeMB: 0.2, // ~200KB
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  let uploadableFile = file;

  try {
    uploadableFile = await imageCompression(file, compressionOptions);
  } catch (compressionError) {
    console.warn(
      "Could not compress image, uploading original file.",
      compressionError
    );
  }

  // 3) Ensure final file size <= 200KB
  if (uploadableFile.size > 200 * 1024) {
    throw new Error("Compressed file is still larger than 200 KB.");
  }

  // 4) Upload to the same path -> automatically overwrites any existing file
  try {
    const storageRef = ref(storage, filePath);
    const snapshot = await uploadBytes(storageRef, uploadableFile);

    // 5) Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error(`Error uploading file: ${error.message}`);
  }
};

export const deleteFileFromStorage = async (filePath) => {
  const fileRef = ref(storage, filePath);
  try {
    await deleteObject(fileRef);
    console.log(`File at ${filePath} deleted successfully.`);
  } catch (error) {
    if (error.code === "storage/object-not-found") {
      console.log(`No file found at ${filePath}. Nothing to delete.`);
    } else {
      console.error("Error deleting file:", error);
      throw new Error(`Error deleting file: ${error.message}`);
    }
  }
};

export const initializeFileStates = (uploadedFiles) => {
  const fileStatus = uploadedFiles
    ? Object.keys(uploadedFiles).reduce((acc, key) => {
        acc[key] = uploadedFiles[key].status || "pending";
        return acc;
      }, {})
    : {};

  const viewedFiles = uploadedFiles
    ? Object.keys(uploadedFiles).reduce((acc, key) => {
        acc[key] = uploadedFiles[key].viewed || false;
        return acc;
      }, {})
    : {};

  return { fileStatus, viewedFiles };
};
